<template>
  <div class="ma-5">
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col cols="12" md="12">
        <v-skeleton-loader type="heading" min-width="100px" class="my-5"></v-skeleton-loader>
        <v-divider></v-divider>
        <v-skeleton-loader type="article" min-width="100px" class="my-5"></v-skeleton-loader>
        <v-skeleton-loader type="article" min-width="100px" class="my-5"></v-skeleton-loader>
        <v-skeleton-loader type="article" min-width="100px" class="my-5"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="!externalAcess" class="pb-2">
      <v-col cols="12" md="12">
        <v-card class="box card-cambio">
          <h1>Tipo de formulário</h1>
          <v-divider></v-divider>
          <v-spacer class="py-2"></v-spacer>
          <v-radio-group v-model="customer.registerType.id" row>
            <v-radio v-for="(item, i) in listOfRegisterTypes" :key="i" :value="item.id"
              :label="item.description"></v-radio>
          </v-radio-group>
        </v-card>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" v-if="customer.registerType.id">
      <div>
        <p style="
          font-style: italic;
          text-align: center;
        ">
          Suas informações serão salvas temporariamente neste dispositivo até que você termine seu cadastro.
        </p>
        <CustomerCover :isPF="isPF" :isExchange="isExchange" :isBasicRemitance="isBasicRemitance"
          :isCompleteRemittance="isCompleteRemittance" v-model="customer" />
        <CustomerContact v-model="customer" />
        <CustomerAddress :isPF="isPF" :isExchange="isExchange" v-model="customer" />
        <CustomerWork v-if="isCompleteRemittance" v-model="customer" />
        <CustomerPartnersShareholders v-model="customer" />
        <CustomerDirectorsProxies v-model="customer" />
        <CustomerFinancial v-model="customer" :isExchange="isExchange" :isLegalPerson="isLegalPerson"
          :isRemitance="isRemitance" :isCompleteRemittance="isCompleteRemittance" />
        <CustomerPoliticallyExposedPerson v-model="customer" />
        <CustomerInternationalAccount v-if="isRemitance" v-model="customer" />
        <CustomerCompliance v-if="isLegalPerson" v-model="customer" />
        <CustomerRegistrationResponsible v-model="customer" />
        <CustomerDocuments :isLegalPerson="isLegalPerson" :isExchange="isExchange" :isBasicRemitance="isBasicRemitance"
          :isCompleteRemittance="isCompleteRemittance" v-model="customer" />
        <CustomerOperationalDocuments v-model="customer" />
        <CustomerUserAgreement v-model="agreeToTerms" :isExchange="isExchange" />
      </div>
    </v-form>
    <v-row v-if="customer.registerType.id">
      <v-col cols="12" md="12">
        <v-btn class="btn-tertiary float-right" text v-if="!isInAnalysis">Cancelar</v-btn>
        <v-btn class="btn-primary mx-2 float-right" @click="!editMode ? save() : update()" text>Salvar</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Components
import CustomerCover from "@/components/form-customer/CustomerCover";
import CustomerContact from "@/components/form-customer/CustomerContact";
import CustomerAddress from "@/components/form-customer/CustomerAddress";
import CustomerPartnersShareholders from "@/components/form-customer/CustomerPartnersShareholders.vue";
import CustomerDirectorsProxies from "@/components/form-customer/CustomerDirectorsProxies.vue";
import CustomerFinancial from "@/components/form-customer/CustomerFinancial.vue";
import CustomerWork from "@/components/form-customer/CustomerWork.vue";
import CustomerPoliticallyExposedPerson from "@/components/form-customer/CustomerPoliticallyExposedPerson.vue";
import CustomerCompliance from "@/components/form-customer/CustomerCompliance.vue";
import CustomerRegistrationResponsible from "../../components/form-customer/CustomerRegistrationResponsible.vue";
import CustomerInternationalAccount from "../../components/form-customer/CustomerInternationalAccount.vue";
import CustomerDocuments from "../../components/form-customer/CustomerDocuments.vue";
import CustomerUserAgreement from "../../components/form-customer/CustomerUserAgreement.vue";
import CustomerOperationalDocuments from "../../components/form-customer/CustomerOperationalDocuments.vue";

// Constants
import {
  REGISTER_TYPE,
  REGISTER_STATUS,
} from "@/constants/general-constants.js";

// Apis
import apiCustomers from "@/api/customer/customer-api";
import apiCustomersDocuments from "@/api/customer/customer-documents-api";
import apiCustomerOperationalDocuments from "@/api/customer/customer-operational-documents-api";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinGeral from "@/mixin/mixin-geral";
import mixinNestedFormValidation from "@/mixin/mixin-nested-form-validation";
import mixinAuthorization from "@/mixin/mixin-authorization";

// Models
import CustomerModel from "@/model/customer-model";
import RegisterTypeModel from "@/model/register-type-model";

// Utils
import storage from "@/utils/storage.js";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "FormCustomer",
  mixins: [
    mixinMessage,
    mixinGeral,
    mixinNestedFormValidation,
    mixinAuthorization
  ],
  props: {
    isInAnalysis: { type: Boolean, default: false },
  },
  components: {
    CustomerCover,
    CustomerContact,
    CustomerAddress,
    CustomerPartnersShareholders,
    CustomerDirectorsProxies,
    CustomerFinancial,
    CustomerWork,
    CustomerPoliticallyExposedPerson,
    CustomerCompliance,
    CustomerRegistrationResponsible,
    CustomerInternationalAccount,
    CustomerDocuments,
    CustomerUserAgreement,
    CustomerOperationalDocuments,
  },
  data() {
    return {
      loading: false,
      editMode: false,
      externalAcess: false,
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
      customer: new CustomerModel(),
      registerType: new RegisterTypeModel(),
      agreeToTerms: false,
      valid: false,
      timer: null,
    };
  },
  mounted() {
    this.externalAcess = true;
    
    let storageCustomer = storage.getCustomerOnStorage();
    storageCustomer = new CustomerModel(storageCustomer);
    if (storageCustomer.registerType.id == this.$route.query.idRegisterType && storageCustomer.correspondent.id == this.$route.query.idAgent) {
      this.customer = storageCustomer;
    } else {
      this.customer.registerType.id = this.$route.query.idRegisterType;
      this.customer.correspondent.id = this.$route.query.idAgent;
    }
  },
  watch: {
    customer: {
      handler() {
        if (!this.externalAcess) {
          return;
        }

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          storage.saveCustomerOnStorage(this.customer);
        }, 5000);
      },
      deep: true,
    },
  },
  computed: {
    isLegalPerson() {
      return this.customer &&
        this.customer.registerType.id == REGISTER_TYPE.LEGAL_PERSON.id
        ? true
        : false;
    },
    isCompleteRemittance() {
      return this.customer &&
        this.customer.registerType.id == REGISTER_TYPE.COMPLETE_REMITTANCE.id
        ? true
        : false;
    },
    isBasicRemitance() {
      return this.customer &&
        this.customer.registerType.id == REGISTER_TYPE.BASIC_REMITTANCE.id
        ? true
        : false;
    },
    isRemitance() {
      return this.customer &&
        this.customer.registerType.id == REGISTER_TYPE.BASIC_REMITTANCE.id ||
        this.customer.registerType.id == REGISTER_TYPE.COMPLETE_REMITTANCE.id
        ? true
        : false;
    },
    isExchange() {
      return this.customer &&
        this.customer.registerType.id == REGISTER_TYPE.EXCHANGE.id
        ? true
        : false;
    },
    isPF() {
      return this.customer &&
        this.customer.registerType.id == REGISTER_TYPE.LEGAL_PERSON.id
        ? false
        : true;
    },
  },
  methods: {
    getCustomerById(id) {
      apiCustomers
        .findId(id)
        .then((response) => {
          this.customer = new CustomerModel(response.data);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          console.log("deu ruim");
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },
    save() {
      mixpanel.track("click", {
        button_name: "save_customer",
      });

      if (!this.isFormValid()) {
        return;
      }

      this.createCustomer();
    },
    update() {
      mixpanel.track("click", {
        button_name: "update_customer",
      });

      if (!this.isInAnalysis && !this.isFormValid()) {
        return;
      }

      this.updateCustomer();
    },
    isFormValid() {
      this.reassignFormInputs(this.$refs.form);
      this.$refs.form.validate();

      if (!this.valid) {
        let invalidElement = this.$refs.form.inputs.find(i => i.valid == false);
        invalidElement.$el.scrollIntoView({ behavior: "smooth", block: "end" });
        return false;
      }

      return true;
    },
    createCustomer() {
      let customerDocuments = this.customer.documents;
      let operationalDocuments = this.customer.operationalDocuments;
      this.prepareCustomerForSaving();

      apiCustomers
        .add(this.customer)
        .then((response) => {
          this.sendMessage("Cliente salvo com sucesso!", "success");

          this.saveDocuments(customerDocuments, response.data);
          this.saveOperationalDocuments(operationalDocuments, response.data);

          storage.removeCustomer();

          this.$router.push({
            name: "CustomerCreationSuccess",
          });
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },
    updateCustomer() {
      this.prepareCustomerForSaving();

      apiCustomers
        .update(this.customer)
        .then(() => {
          this.sendMessage("Cliente salvo com sucesso!", "success");

          storage.removeCustomer();

          // if (this.externalAcess) {
          //   storage.removeAuth();
          //   this.$router.push({
          //     name: "Login",
          //   });
          // } else {
          //   this.$router.push({
          //     name: "CustomersControl",
          //   });
          // }
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },
    saveDocuments(customerDocuments, customer) {
      customerDocuments?.forEach((document) => {
        let formData = new FormData();

        formData.append("customerId", customer.id);
        formData.append("idDocumentType", document.documentType.id);
        formData.append("document", document.document);

        apiCustomersDocuments.add(formData);
      });
    },
    saveOperationalDocuments(operationalDocuments, customer) {
      operationalDocuments?.forEach((document) => {
        let formData = new FormData();

        formData.append("customerId", customer.id);
        formData.append("idOperationalDocumentType", document.operationalDocumentType.id);
        formData.append("document", document.document);

        apiCustomerOperationalDocuments.add(formData);
      });
    },
    prepareCustomerForSaving() {
      this.customer.documents = undefined;
      this.customer.operationalDocuments = undefined;
      this.customer.registerStatus = REGISTER_STATUS.PARTIALLY_FILLED;
      this.customer.compliance.suppliers = this.customer.compliance.suppliersProxy.map(i => i.name).join(';');
      this.customer.compliance.products = this.customer.compliance.productsProxy.map(i => i.name).join(';');
      this.customer.compliance.typeExportedProducts = this.customer.compliance.typeExportedProductsProxy.map(i => i.name).join(';');
      this.customer.compliance.dis = this.customer.compliance.disProxy.map(i => i.name).join(';');
    },
  },
};
</script>
