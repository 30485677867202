<template>
  <v-row :key="key" class="py-0">
    <v-col class="py-0 pt-4" cols="12" md="12">
      <v-row>
        <template v-for="(contract, i) in socialContracts">
          <v-col class="py-0" cols="12" md="6" :key="'fileSocial' + i">
            <DocumentInput
              :description="'Contrato social / Estatuto social ' + (i+1)"
              v-model="socialContracts[i]"
              :api="apiDocument"
              :customerId="customer.id"
              :required="socialContracts[i].required"
            >
              <template v-slot:append-outer>
                <v-btn 
                  v-if="socialContracts.length >= 2 && !(socialContracts[i].document || socialContracts[i].path)"
                  class="py-0"
                  icon
                  @click="removeContract(i)"
                  text
                >
                  <i class="far fa-trash"></i>
                </v-btn>
                <v-btn class="py-0" icon @click="insertContract" text>
                  <i class="far fa-plus" ></i>
                </v-btn>
              </template>
            </DocumentInput>
          </v-col>
        </template>
        <v-col class="py-4" cols="12" md="12">
          <v-divider class="py-3"></v-divider>
        </v-col>
      </v-row>
    </v-col>
    <template v-for="(customerDocument, i) in documents">
      <v-col
        v-if="i != 0 && i % 2 == 0"
        class="py-4"
        cols="12"
        md="12"
        :key="'dividerDocument' + i"
      >
        <v-divider class="py-3"></v-divider>
      </v-col>
      <v-col class="py-0" cols="12" md="6" :key="'fileDocument' + i">
        <DocumentInput
          v-model="documents[i]"
          :api="apiDocument"
          :customerId="customer.id"
          :required="documents[i].required"
        />
      </v-col>
    </template>
    <v-col class="py-0 pt-4" cols="12" md="12">
      <v-row>
        <v-col class="py-4" cols="12" md="12">
          <v-divider class="py-3"></v-divider>
        </v-col>
        <template v-for="(other, i) in others">
          <v-col class="py-0" cols="12" md="6" :key="'others' + i">
            <DocumentInput
              :description="'Outros ' + (i+1)"
              v-model="others[i]"
              :api="apiDocument"
              :customerId="customer.id"
              :required="others[i].required"
            >
              <template v-slot:append-outer>
                <v-btn 
                  v-if="others.length >= 2 && !(others[i].document || others[i].path)"
                  class="py-0"
                  icon
                  @click="removeOther(i)"
                  text
                >
                  <i class="far fa-trash"></i>
                </v-btn>
                <v-btn class="py-0" icon @click="insertOther" text>
                  <i class="far fa-plus" ></i>
                </v-btn>
              </template>
            </DocumentInput>
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// Components
import DocumentInput from "@/components/comum/DocumentInput";

// Constants
import { DOCUMENT_TYPE } from "@/constants/general-constants.js";
import CustomerDocumentModel from "../../../../model/customer-document-model";

// APIs
import apiCustomersDocuments from "@/api/customer/customer-documents-api";

export default {
  name: "PJCustomerDocuments",
  components: { DocumentInput },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  data() {
    return {
      customer: this.customerProp,
      documents: [],
      documentTypes: DOCUMENT_TYPE,
      apiDocument: apiCustomersDocuments,
      key: 0,
      socialContracts: [],
      others: [],
    };
  },
  mounted() {
    this.mountDocuments();
  },
  watch: {
    "customer.additional.operationNature.id"() {
      this.mountDocuments();
    },
    "customer.registerType.id"() {
      this.mountDocuments();
    },
    moreThanSixtyKYearly() {
      this.mountDocuments();
    },
    documents: {
      handler() {
        this.updateCustomerDocuments();
      },
      deep: true,
    },
    socialContracts: {
      handler() {
        this.updateCustomerDocuments();
      },
      deep: true,
    },
    others: {
      handler() {
        this.updateCustomerDocuments();
      },
      deep: true,
    },
  },
  methods: {
    mountDocuments() {
      this.documents = [];

      let groupedDocuments = this.customer.documents.reduce((grouped, current) => {
          const value = current.documentType.id;
          grouped[value] = (grouped[value] || []).concat(current);
          return grouped;
      }, {});

      // Comprovante Endereco
      let comprovanteEndereco = groupedDocuments[3]?.shift() ||
        new CustomerDocumentModel({ documentType: this.documentTypes[2] });
      this.documents.push({ ...comprovanteEndereco, required: true });

      // Faturamento dos últimos 12 meses
      let faturamento = groupedDocuments[9]?.shift() ||
        new CustomerDocumentModel({ documentType: this.documentTypes[8] });
      this.documents.push({ ...faturamento, required: true });

      // Balanço e DRE
      let balancoDre = groupedDocuments[10]?.shift() ||
        new CustomerDocumentModel({ documentType: this.documentTypes[9] });
      this.documents.push({ ...balancoDre, required: true });

      // Cartão do CNPJ
      let cartaoCNPJ = groupedDocuments[11]?.shift() ||
        new CustomerDocumentModel({ documentType: this.documentTypes[10] });
      this.documents.push({ ...cartaoCNPJ, required: true });

      // Procuração
      let procuracao = groupedDocuments[12]?.shift() ||
        new CustomerDocumentModel({ documentType: this.documentTypes[11] });
      this.documents.push({ ...procuracao, required: true });

      // Radar
      let radar = groupedDocuments[13]?.shift() ||
        new CustomerDocumentModel({ documentType: this.documentTypes[12] });
      this.documents.push({ ...radar, required: true });

      // Contrato Social
      this.socialContracts = groupedDocuments[8] ||
        [ { ...(new CustomerDocumentModel({ documentType: this.documentTypes[7] })), required: true } ];
      
      // Outros
      this.others = groupedDocuments[7] ||
        [ new CustomerDocumentModel({ documentType: this.documentTypes[6] }) ];

      this.key = this.key + 1;
    },
    removeContract(index) {
      if (this.socialContracts.length > 1) {
        this.socialContracts.splice(index, 1);
        this.key = this.key + 1;
      }
    },
    insertContract() {
      let contract = new CustomerDocumentModel({ documentType: this.documentTypes[7] })
      this.socialContracts.push({ ...contract, required: true });
    },
    removeOther(index) {
      if (this.others.length > 1) {
        this.others.splice(index, 1);
        this.key = this.key + 1;
      }
    },
    insertOther() {
      this.others.push(new CustomerDocumentModel({ documentType: this.documentTypes[6] }));
    },
    updateCustomerDocuments() {
      let documents = this.documents.filter((d) => d.document);
      let contracts = this.socialContracts.filter((d) => d.document);
      let others = this.others.filter((d) => d.document);
      this.customer.documents = [...documents, ...contracts, ...others];
      this.$emit("onChange", this.customer);
    },
  },
};
</script>

<style>

</style>