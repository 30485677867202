import FormCustomer from '@/views/customers/FormCustomer';
import CustomerCreationSuccess from '@/views/customers/CustomerCreationSuccess';

const routes = [
  {
    path: "/customers/external-acess",
    name: "FormEditCustomerExternalAcess",
    component: FormCustomer,
    title: "Criação e Edição de Clientes",
    meta: { requireAuth: false },
  },
  {
    path: "/customers/created-success",
    name: "CustomerCreationSuccess",
    component: CustomerCreationSuccess,
    title: "Cadastro Bem Sucedido",
    meta: { requireAuth: false },
  },
  { path: '/:catchAll(.*)', redirect: { name: 'FormEditCustomerExternalAcess' } },
];

export default routes;