<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <i :class="'far fa-info-circle icon-tooltip fa-' + iconSize" v-bind="attrs" v-on="on"></i>
    </template>
    <span>{{ label }}</span>
  </v-tooltip>
</template>

<script>

export default {
  name: "InfoTooltip",
  props: {
    label: {
      type: String,
      required: true,
    },
    iconSize: {
      type: String,
      required: false,
      default: '1.6x'
    }
  },
};
</script>

<style lang="scss" scoped>

.icon-tooltip{
  cursor: pointer;
  color: $primary_color;
  font-weight: 200;
}
</style>
