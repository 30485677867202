const PROFILES = [
  { id: 1, description: 'Administrador' },
  { id: 2, description: 'Correspondente' },
  { id: 3, description: 'Cliente' }
];

const REGISTER_TYPE = {
  EXCHANGE: { id: 1, description: 'Pessoa Física Câmbio Turismo' },
  BASIC_REMITTANCE: { id: 2, description: 'Pessoa Física Remessa Básico' },
  COMPLETE_REMITTANCE: { id: 3, description: 'Pessoa Física Remessa Completo' },
  LEGAL_PERSON: { id: 4, description: 'Pessoa Jurídica' }
};

// In the future, save this information in database

const PERSON_TYPE = [
  { 'id': 1, 'description': 'Pessoa jurídica' },
  { 'id': 2, 'description': 'Pessoa física' },
];

const MARITAL_STATUS = {
  SINGLE: { 'id': 1, 'description': 'Solteiro(a)' },
  MARRIED: { 'id': 2, 'description': 'Casado(a)' },
  DIVORCED: { 'id': 3, 'description': 'Divorciado(a)' },
  STABLE_UNION: { 'id': 4, 'description': 'União estável' },
  WIDOWED: { 'id': 5, 'description': 'Viúvo(a)' },
};

const MARRIAGE_SYSTEM = {
  PARTIAL_COMMUNION_OF_GODS: { 'id': 1, 'description': 'Comunhão parcial de bens' },
  UNIVERSAL_COMMUNION_OF_GODS: { 'id': 2, 'description': 'Comunhão universão de bens' },
  STABLE_UNION: { 'id': 3, 'description': 'União estável' },
  SEPARATION_OF_GODS: { 'id': 4, 'description': 'Separação de Bens' },
  SEPARATION_OF_GODS_FREEDOM_OF_CHOICE: { 'id': 5, 'description': 'Separação de Bens: Liberdade de escolha' },
  PARTIAL_COMMUNION_OF_GODS_JUSTICE_AND_UNION: { 'id': 6, 'description': 'Comunhão parcial de bens: Justiça e união' },
};

const DOCUMENT_TYPE = [
  // The numbers are the operation nature ids
  {
    'id': 1,
    'description': 'Doc. Identificação',
    'additionalInfo': 'RG, CNH ou documento profissional',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [1, 2, 3, 4],
    'basicRemittanceOptionalOperationsId': [],
    'basicRemittanceRequiredOperationsId': [1, 2, 3, 4],
    'completeRemittanceOptionalOperationsId': [],
    'completeRemittanceRequiredOperationsId': [1, 2, 3, 4],
    'legalOptionalOperationsId': [],
    'legalRequiredOperationsId': [],
  },
  {
    'id': 2,
    'description': 'Doc. Identificação Verso',
    'additionalInfo': '',
    'exchangeOptionalOperationsId': [1, 2, 3, 4],
    'exchangeRequiredOperationsId': [],
    'basicRemittanceOptionalOperationsId': [1, 2, 3, 4],
    'basicRemittanceRequiredOperationsId': [],
    'completeRemittanceOptionalOperationsId': [1, 2, 3, 4],
    'completeRemittanceRequiredOperationsId': [],
    'legalOptionalOperationsId': [],
    'legalRequiredOperationsId': [],
  },
  {
    'id': 3,
    'description': 'Comprovante de endereço',
    'additionalInfo': 'Água, Luz ou Telefone - Validade máxima de 60 dias',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [1, 2, 3, 4],
    'basicRemittanceOptionalOperationsId': [],
    'basicRemittanceRequiredOperationsId': [1, 2, 3, 4],
    'completeRemittanceOptionalOperationsId': [],
    'completeRemittanceRequiredOperationsId': [1, 2, 3, 4],
    'legalOptionalOperationsId': [],
    'legalRequiredOperationsId': [1, 2, 3, 4, 5, 6],
  },
  {
    'id': 4,
    'description': 'Declaração de imposto de renda',
    'additionalInfo': '',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [],
    'basicRemittanceOptionalOperationsId': [],
    'basicRemittanceRequiredOperationsId': [],
    'completeRemittanceOptionalOperationsId': [],
    'completeRemittanceRequiredOperationsId': [1, 2, 3, 4],
    'legalOptionalOperationsId': [],
    'legalRequiredOperationsId': [],
  },
  {
    'id': 5,
    'description': 'Recibo de imposto de renda',
    'additionalInfo': '',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [],
    'basicRemittanceOptionalOperationsId': [],
    'basicRemittanceRequiredOperationsId': [],
    'completeRemittanceOptionalOperationsId': [],
    'completeRemittanceRequiredOperationsId': [1, 2, 3, 4],
    'legalOptionalOperationsId': [],
    'legalRequiredOperationsId': [],
  },
  {
    'id': 6,
    'description': 'Contrato de compra de imóveis',
    'additionalInfo': '',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [],
    'basicRemittanceOptionalOperationsId': [2],
    'basicRemittanceRequiredOperationsId': [],
    'completeRemittanceOptionalOperationsId': [2],
    'completeRemittanceRequiredOperationsId': [],
    'legalOptionalOperationsId': [],
    'legalRequiredOperationsId': [],
  },
  {
    'id': 7,
    'description': 'Outros',
    'additionalInfo': '',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [],
    'basicRemittanceOptionalOperationsId': [],
    'basicRemittanceRequiredOperationsId': [],
    'completeRemittanceOptionalOperationsId': [],
    'completeRemittanceRequiredOperationsId': [],
    'legalOptionalOperationsId': [],
    'legalRequiredOperationsId': [],
  },
  {
    'id': 8,
    'description': 'Contrato social / Estatuto social',
    'additionalInfo': '',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [],
    'basicRemittanceOptionalOperationsId': [],
    'basicRemittanceRequiredOperationsId': [],
    'completeRemittanceOptionalOperationsId': [],
    'completeRemittanceRequiredOperationsId': [],
    'legalOptionalOperationsId': [],
    'legalRequiredOperationsId': [],
  },
  {
    'id': 9,
    'description': 'Faturamento dos últimos 12 meses',
    'additionalInfo': '',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [],
    'basicRemittanceOptionalOperationsId': [],
    'basicRemittanceRequiredOperationsId': [],
    'completeRemittanceOptionalOperationsId': [],
    'completeRemittanceRequiredOperationsId': [],
    'legalOptionalOperationsId': [1, 2, 3, 4, 5, 6],
    'legalRequiredOperationsId': [],
  },
  {
    'id': 10,
    'description': 'Balanço e DRE',
    'additionalInfo': '',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [],
    'basicRemittanceOptionalOperationsId': [],
    'basicRemittanceRequiredOperationsId': [],
    'completeRemittanceOptionalOperationsId': [],
    'completeRemittanceRequiredOperationsId': [],
    'legalOptionalOperationsId': [1, 2, 3, 4, 5, 6],
    'legalRequiredOperationsId': [],
  },
  {
    'id': 11,
    'description': 'Cartão do CNPJ',
    'additionalInfo': '',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [],
    'basicRemittanceOptionalOperationsId': [],
    'basicRemittanceRequiredOperationsId': [],
    'completeRemittanceOptionalOperationsId': [],
    'completeRemittanceRequiredOperationsId': [],
    'legalOptionalOperationsId': [1, 2, 3, 4, 5, 6],
    'legalRequiredOperationsId': [],
  },
  {
    'id': 12,
    'description': 'Procuração',
    'additionalInfo': '',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [],
    'basicRemittanceOptionalOperationsId': [],
    'basicRemittanceRequiredOperationsId': [],
    'completeRemittanceOptionalOperationsId': [],
    'completeRemittanceRequiredOperationsId': [],
    'legalOptionalOperationsId': [1, 2, 3, 4, 5, 6],
    'legalRequiredOperationsId': [],
  },
  {
    'id': 13,
    'description': 'Radar',
    'additionalInfo': '',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [],
    'basicRemittanceOptionalOperationsId': [],
    'basicRemittanceRequiredOperationsId': [],
    'completeRemittanceOptionalOperationsId': [],
    'completeRemittanceRequiredOperationsId': [],
    'legalOptionalOperationsId': [1, 2, 3, 4, 5, 6],
    'legalRequiredOperationsId': [],
  },
  {
    'id': 14,
    'description': 'Ficha cadastral de cada sócio',
    'additionalInfo': '',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [],
    'basicRemittanceOptionalOperationsId': [],
    'basicRemittanceRequiredOperationsId': [],
    'completeRemittanceOptionalOperationsId': [],
    'completeRemittanceRequiredOperationsId': [],
    'legalOptionalOperationsId': [],
    'legalRequiredOperationsId': [],
  },
  {
    'id': 15,
    'description': 'Documentos da PJ no exterior',
    'additionalInfo': '',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [],
    'basicRemittanceOptionalOperationsId': [4],
    'basicRemittanceRequiredOperationsId': [],
    'completeRemittanceOptionalOperationsId': [4],
    'completeRemittanceRequiredOperationsId': [],
    'legalOptionalOperationsId': [],
    'legalRequiredOperationsId': [],
  },
  {
    'id': 16,
    'description': 'Passaporte',
    'additionalInfo': '',
    'exchangeOptionalOperationsId': [],
    'exchangeRequiredOperationsId': [],
    'basicRemittanceOptionalOperationsId': [],
    'basicRemittanceRequiredOperationsId': [],
    'completeRemittanceOptionalOperationsId': [],
    'completeRemittanceRequiredOperationsId': [],
    'legalOptionalOperationsId': [],
    'legalRequiredOperationsId': [],
  },
];

const ADDRESS_TYPE = {
  RESIDENTIAL: { 'id': 1, 'description': 'Residencial' },
  DELIVERY: { 'id': 2, 'description': 'Entrega' },
  PROFESSIONAL: { 'id': 3, 'description': 'Trabalho' },
};

const IDENTIFICATION_DOCUMENT_TYPE = {
  RG: { 'id': 1, 'description': 'RG' },
  CNH: { 'id': 2, 'description': 'CNH' },
  Funcional: { 'id': 3, 'description': 'Funcional' },
  'RNE/RNM': { 'id': 4, 'description': 'RNE/RNM' },
  Passaporte: { 'id': 5, 'description': 'Passaporte' },
};


const LIST_IDENTIFICATION_DOCUMENT_TYPE = [
  IDENTIFICATION_DOCUMENT_TYPE.RG,
  IDENTIFICATION_DOCUMENT_TYPE.CNH,
  IDENTIFICATION_DOCUMENT_TYPE.Funcional,
  IDENTIFICATION_DOCUMENT_TYPE["RNE/RNM"],
  IDENTIFICATION_DOCUMENT_TYPE.Passaporte,
];

// const NATIONAL_BANKS = [
//   { 'id': 1, 'description': 'national bank 1' },
//   { 'id': 2, 'description': 'national bank 2' },
// ];

// const INTERNATIONAL_BANKS = [
//   { 'id': 1, 'description': 'international bank 1' },
//   { 'id': 2, 'description': 'international bank 2' },
// ];

const FORM_OF_INCOPORATION = [
  { 'id': 1, 'description': 'MEI' },
  { 'id': 2, 'description': 'ME' },
  { 'id': 3, 'description': 'Simples Nacional' },
  { 'id': 4, 'description': 'LTDA' },
  { 'id': 5, 'description': 'S/A' },
]

const OPERATING_MODE = [
  { 'id': 1, 'description': 'Por conta própria' },
  { 'id': 2, 'description': 'Por conta e ordem de terceiros. Neste caso, informe o nome e CNPJ do principal prestador de serviços. Utilize o campo "informações adicionais"' },
]

const HABILITATION_MODE = [
  { 'id': 1, 'description': 'Limitado (antigo simplificado)' },
  { 'id': 2, 'description': 'Ilimitado (antigo ordinário)' },
  { 'id': 3, 'description': 'Expressa' },
]

const PAYMENT_MODE = [
  { 'id': 1, 'description': 'Antecipado/A vista (Sem DI)' },
  { 'id': 2, 'description': 'Pronta (Com DI)' },
  { 'id': 3, 'description': 'Ambos' },
]

const BUSINESS_PURPOSE_TYPE = [
  { 'id': 1, 'description': 'Câmbio Comercial (Importação e Exportação)' },
  { 'id': 2, 'description': 'Câmbio, Transferências Unilaterais e Turismo (Diponibilidade, Patrimônio, Manut. Res., etc' },
  { 'id': 3, 'description': 'Câmbio Financeiro (Investimento, Empréstimos, Juros, Serviços, etc' },
  { 'id': 4, 'description': 'Conta Corrente Não Residente (TIR)' },
  { 'id': 5, 'description': 'Hedge e Derivativos no Exterior' },
  { 'id': 6, 'description': 'Conta Corrente em Moeda Estrangeira' },
  { 'id': 7, 'description': 'Outros Produtos e Serviços (Informar)' },
];

const CORRESPONDING = [
  { id: 0, name: 'João da Silva' },
  { id: 1, name: 'Maria da Silva' },
  { id: 2, name: 'Pedro da Silva' },
];

const BANKS = [
  { id: 0, description: 'Banco BS2' },
  { id: 1, description: 'Banco Ourinvest' },
  { id: 2, description: 'Treviso Corretora de Câmbio S/A' },
];


const ACCESS_ROUTES = {
  DASHBOARD: {
    description: "Dashboard",
    profiles: [1, 2]
  },
  USERS: {
    description: "Usuários",
    profiles: [1]
  },
};

const REGISTER_STATUS = {
  SENT_EMAIL: { 'id': 1, 'description': 'Email Enviado' },
  PARTIALLY_FILLED: { 'id': 2, 'description': 'Cadastro Parcialmente Preenchido' },
  ANALYSIS: { 'id': 3, 'description': 'Aguardando Análise' },
  PENDENCY: { 'id': 4, 'description': 'Pendência Cadastral' },
  SENT_TO_BANK: { 'id': 5, 'description': 'Enviado ao banco' },
  APPROVED: { 'id': 6, 'description': 'Aprovado' },
  UPDATE: { 'id': 7, 'description': 'Atualização Cadastral' },
};

const ASSETS = [
  { id: 0, description: 'Até R$ 100 Mil Reais' },
  { id: 1, description: 'Até R$ 300 Mil Reais' },
  { id: 2, description: 'Até R$ 500 Mil Reais' },
  { id: 3, description: 'Até R$ 1 Milhão de Reais' },
  { id: 4, description: 'Acima de R$ 1 Milhão de Reais' },
]

const OPERATIONAL_DOCUMENT_TYPE = [
  { 'id': 1, 'description': 'doc 1' },
  { 'id': 2, 'description': 'doc 2' },
  { 'id': 3, 'description': 'doc 3' },
  { 'id': 4, 'description': 'doc 4' },
  { 'id': 5, 'description': 'doc 5' },
  { 'id': 6, 'description': 'doc 6' },
];

const LIQUIDATION = [
  { id: 1, description: 'D+0/D+0' },
  { id: 2, description: 'D+0/D+1' },
  { id: 3, description: 'D+0/D+2' },
  { id: 4, description: 'D+1/D+1' },
  { id: 5, description: 'D+1/D+2' },
  { id: 6, description: 'D+2/D+2' },
];

const OPERATION_DOCUMENT_TYPES = {
  DIDUE: {
    'id': 1,
    'description': 'DI/DUE',
  },
  SWIFT: {
    'id': 2,
    'description': 'SWIFT',
  },
  CC: {
    'id': 3,
    'description': 'Contrato de Câmbio',
  },
  COMPROVANTE: {
    'id': 4,
    'description': 'Comprovante de Pagamento',
  },
};

const OPERATION_DOCUMENT_TYPE_LIST = Object.values(OPERATION_DOCUMENT_TYPES);

export {
  ACCESS_ROUTES,
  PROFILES,
  REGISTER_TYPE,
  PERSON_TYPE,
  MARITAL_STATUS,
  MARRIAGE_SYSTEM,
  DOCUMENT_TYPE,
  ADDRESS_TYPE,
  IDENTIFICATION_DOCUMENT_TYPE,
  LIST_IDENTIFICATION_DOCUMENT_TYPE,
  CORRESPONDING,
  BANKS,
  FORM_OF_INCOPORATION,
  BUSINESS_PURPOSE_TYPE,
  PAYMENT_MODE,
  HABILITATION_MODE,
  OPERATING_MODE,
  REGISTER_STATUS,
  ASSETS,
  OPERATIONAL_DOCUMENT_TYPE,
  LIQUIDATION,
  OPERATION_DOCUMENT_TYPE_LIST,
  OPERATION_DOCUMENT_TYPES,
}