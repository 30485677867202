import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueMask from 'v-mask';
import money from 'v-money';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@/static/styles/_index.scss';
import mixpanel from 'mixpanel-browser'
import * as Sentry from "@sentry/vue";
import utilsStorage from "@/utils/storage";

Vue.config.productionTip = false

Vue.prototype.$eventBus = new Vue();

Vue.use(VueMask);
Vue.use(money, { precision: 2 });

Vue.use(mixpanel);

Vue.directive('disabledIconFocus', {
  bind(el) {
    disableIconFocus(el);
  },
  componentUpdated(el) {
    disableIconFocus(el);
  },
});

function disableIconFocus(el) {
  el.querySelectorAll('.v-input__icon button').forEach(x => x.setAttribute('tabindex', -1));
}

function getUserData() {
  let user = utilsStorage.getUserOnStorage()

  if (user) {
    Sentry.setUser(
      {
        email: user.mail,
        id: user.id,
        username: user.name
      }
    )
  }
}

getUserData()

Sentry.init({
  Vue,
  dsn: "https://0f0bf7dc12784f418569693c55b4eb79@o1319164.ingest.sentry.io/4505480309374976",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["production", "https://semear.cambiohoje.com.br"],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

mixpanel.init('1eb25b087b1df61ceeeb11cb7241deb5')

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
