<template>
  <div class="container">
    <v-card class="card" elevation="0">
      <v-row>
        <v-col md="12" cols='12'>
          <check-circle-icon width="80px" height="80px"></check-circle-icon>
        </v-col>
        <v-col md="12" cols='12'>
          <h1 class="title">Ficha atualizada com sucesso!</h1>
          
          <h2 class="subtitle">Em breve, um agente do entrará em contato com você para dar prosseguimento a sua solicitação</h2>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "CustomerCreationSuccess",
  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
* {
  color: #5667D6
}

.title{
  margin: 20px;
}

.subtitle{
  margin: 30px;
  color: black;
}

.card {
  text-align: center;
  padding: 20px;
}

.container {
  margin-top: 10%;
}

</style>