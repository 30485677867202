'use strict';

const ENVIROMENT = {
  //LOCAL: "http://localhost:3333",
  // HOMOLOGATION: "https://hml-cambiohoje.deeprocket.com.br/api",
  PRODUCTION: "https://semear.cambiohoje.com.br/api",
}

const URLS = {
  //BASE: ENVIROMENT.LOCAL,
  // BASE: ENVIROMENT.HOMOLOGATION,
  BASE: ENVIROMENT.PRODUCTION,

  VIACEP: "https://viacep.com.br/ws",
  IBGE: "https://servicodados.ibge.gov.br",
  BRASILAPI: "https://brasilapi.com.br/api"
}

const VERSION = 'v0.0.15';

export {
  URLS,
  VERSION,
}
